<template>
  <div class="root">
    <div
      v-for="(step, index) in steps"
      :key="step"
      :class="getStepClass(index)"
    >
      <div class="header">
        <div class="circle"></div>
        <div class="line" />
      </div>
      <div class="step-number">
        {{ $t("payment.step", { step: index + 1 }) }}
      </div>
      <div class="name">
        {{ $t(step) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: Array,
    stepNumber: Number,
  },

  methods: {
    getStepClass(index) {
      const classes = ["step"];

      if (index + 1 === this.stepNumber) {
        classes.push("step-current");
      }

      if (index + 1 < this.stepNumber) {
        classes.push("step-done");
      }

      return classes.join(" ");
    },
  },
};
</script>

<style scoped>
.root {
  display: flex;
}

.step {
  flex-grow: 1;
  width: 33%;
}

.circle {
  height: 12px;
  width: 12px;
  border: 2px solid #ffffff29;
  border-radius: 50%;
}

.header {
  display: flex;
  align-items: center;
}

.line {
  flex-grow: 1;
  height: 1px;
  background: #ffffff29;
}

.step-current .circle {
  border-color: #4e4bcd;
}

.step-done .circle {
  border-color: #4e4bcd;
  background: #4e4bcd;
}

.step-done .line {
  background: #4e4bcd;
}

.step-number {
  text-transform: uppercase;
  color: #999;
  font-size: 10px;
  margin-top: 8px;
}

.name {
  font-size: 13px;
  color: #999;
}

.step-done .name,
.step-current .name {
  color: #fff;
}
</style>
